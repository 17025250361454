<template>
  <div class="card-radion-button-steps">
    <div class="background-left-proposal"/>
    <div class="d-flex">
      {{text}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'cardRadioButton',
  props: {
    text: {
      type: String,
    },
  },
}
</script>

<style>
.card-radion-button-steps {
  border-radius: 1em !important;
  background-color: white;
  overflow: hidden !important;
  padding: 2em;
  border: 0.0625rem solid rgba(222,226,230,.7); 
  position: relative;
}
.card-radion-button-steps:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  transition: 500ms;
}
.background-left-proposal {
  background-color: #dee2e6b3;
  width: 10px;
  height: 220px;
  position: absolute;
  top: 0;
  left: 0px;
}
</style>