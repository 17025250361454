<template>
  <layout-stepper>
    <template #body>
      <b-link @click="$router.push({name: 'step_category'})" v-if="!has_slug">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        Previous Step
      </b-link>
      <div class="d-flex justify-content-center">
        <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
          <b-col class="col-12 text-center">
            <h1 class="mb-1">What's your focus in {{work_type.text}}?</h1>
            <p>Now pick a subcategory for your listing to appear under.</p>
          </b-col>
          <b-col 
            class="col-12 col-md-6 mt-1" 
            v-for="(i, index) in $t(`creator.focus_in_${work_type.value}`)" 
            :key="index"
            ref="card_radio_button"
            @click="getActive(i, index)"
          >
            <card-radio-button :text="i.text"></card-radio-button>
          </b-col>
          <b-col class="col-12 d-flex justify-content-center">
            <b-button variant="primary" class="my-2" :disabled="selected === null" @click="save()">{{has_slug ? 'Save and preview' : 'Next step'}}</b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
} from 'bootstrap-vue';
import layoutStepper from '@/views/components/creator/layoutStepper.vue';
import cardRadioButton from '@/views/components/creator/cardRadioButton.vue';
import creator_services from '@/services/creator';

export default {
  name: 'subcategory',
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    layoutStepper,
    cardRadioButton,
  },
  data() {
    return {
      selected: null,
      work_type: {
        text: ''
      },
      steps: null,
      index_actived: 0,
      proposal: {}
    }
  },
  created() {
    setTimeout(() => {
      this.getData();
    }, 100);
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    getActive(value, index) {
      this.clearActive();
      this.$refs.card_radio_button[index].classList.add('active-class-button');
      this.selected = value;
      this.index_actived = index;
    },
    clearActive() {
      for (let index = 0; index < this.$t(`creator.focus_in_${this.work_type.value}`).length; index++) {
        this.$refs.card_radio_button[index].classList.remove('active-class-button')
      }
    },
    getData() {
      if (this.$route.params.slug !== undefined) { 
        creator_services.detailProposal(this.$route.params.slug).then((response) => {
          this.work_type = this.$t(`creator.type_of_work`).find((i) => i.value === response.work_type)
          this.proposal = response;
          let index_work = 0;
          const content_type = this.$t(`creator.focus_in_${response.work_type}`).find((i, index) => {
            console.log(index)
            index_work = index;
            return i.value === response.content_type;
          });
          this.getActive(content_type, index_work);
        })
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.work_type = this.steps.work_type;
        if (this.steps.content_type) {
          this.getActive(this.steps.content_type, this.steps.index_actived[1])
        }
      }
    },
    save() {
      if (this.$route.params.slug !== undefined) {
        creator_services.updateProposal(this.proposal.uuid, {content_type: this.selected.value}).then(() => {
          this.$router.push({name: 'step_preview', params: {slug: this.proposal.slug}})
        });
      } else {
        this.steps['content_type'] = this.selected;
        this.steps.index_actived[1] = this.index_actived;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({name: 'step_add_connection'});
      }
    }
  },
}
</script>
<style>
.active-class-button > .card-radion-button-steps {
  background-color: #7267f005 !important;
  border: 0.0625rem solid #7267f053; 
}
.active-class-button > .card-radion-button-steps > .background-left-proposal {
  background-color: #7367f0 !important;
}
</style>